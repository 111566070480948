import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as DetectRTC from 'detectrtc';

/**
 * File node data with nested structure.
 * Each node has a filename, and a type or a list of children.
 */
 export class FileNode {
  children: FileNode[];
  filename: string;
  type: any;
}

/** Flat node with expandable and level information */
export class FileFlatNode {
  constructor(
    public expandable: boolean, public filename: string, public level: number, public type: any) {}
}

@Injectable({
  providedIn: 'root'
})
export class BrowserInformationTreeService {

  dataChange = new BehaviorSubject<FileNode[]>([]);

  get data(): FileNode[] { return this.dataChange.value; }
  dataObject;

  constructor() {
    this.initialize();
  }

  async initialize() {
    // Parse the string to json object.
    await this.getGeneralBrowserInformation().then((res: Object) => {
      console.log(res);
      const data = this.buildFileTree(res, 0);

      // Notify the change.
      this.dataChange.next(data);
    });


  }

  async getGeneralBrowserInformation() {

    const promise = new Promise((resolve) => {
      DetectRTC.load(() => {
         resolve({
          'Browser': {
            'Browser Name': DetectRTC.browser.name,
            'Version': DetectRTC.browser.fullVersion,
            'Private Browsing': DetectRTC.browser.isPrivateBrowsing,
          },
          'Operating System': {
            'OS Name': DetectRTC.osName,
            'Version': DetectRTC.osVersion,
          },
          'Display': {
            'Resolution': DetectRTC.displayResolution,
            'Display Aspect Ratio': DetectRTC.displayAspectRatio
          },
          'Media Devices': DetectRTC.MediaDevices,
          'Audio Input Devices': DetectRTC.audioInputDevices,
          'Audio Output Devices': DetectRTC.audioOutputDevices,
          'Video Input Devices': DetectRTC.videoInputDevices
        });
      });
    });

    return promise;

  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `FileNode`.
   */
  buildFileTree(obj: object, level: number): FileNode[] {
    return Object.keys(obj).reduce<FileNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new FileNode();
      node.filename = key;

      if (value != null) {
        if (typeof value === 'object') {
          node.children = this.buildFileTree(value, level + 1);
        } else {
          node.type = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }
}
