import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { MatTreeFlattener, MatTreeFlatDataSource, MatDialogRef } from '@angular/material';
import * as DetectRTC from 'detectrtc';
import { Observable,of } from 'rxjs';
import { BrowserInformationTreeService, FileFlatNode, FileNode } from './browser-information-tree.service';

export interface RTCFeature {
  position: string;
  name: string;
  supported: boolean;
}


@Component({
  selector: 'sal-detect-rtc-modal',
  templateUrl: './detect-rtc-modal.component.html',
  styleUrls: ['./detect-rtc-modal.component.scss'],
  providers: [BrowserInformationTreeService]
})
export class DetectRtcModalComponent implements OnInit {
  treeControl: FlatTreeControl < FileFlatNode > ;
  treeFlattener: MatTreeFlattener < FileNode,
  FileFlatNode > ;
  dataSource: MatTreeFlatDataSource < FileNode,
  FileFlatNode > ;

  displayedColumns: string[] = ['position', 'name', 'supported'];
  tableData: RTCFeature[];


  constructor(private browserTreeInfoService: BrowserInformationTreeService, public dialogRef: MatDialogRef<DetectRtcModalComponent>) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this._getLevel,
    this._isExpandable, this._getChildren);
    this.treeControl = new FlatTreeControl < FileFlatNode > (this._getLevel, this._isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this.browserTreeInfoService.dataChange.subscribe(data => this.dataSource.data = data);
  }

  ngOnInit() {
    DetectRTC.load(() => {
      console.log(DetectRTC);
      // This is reliable
      this.tableData = [{
        position: '1',
        name: 'Web Rtc Support',
        supported: DetectRTC.isWebRTCSupported,
      },
      {
        position: '2',
        name: 'Camera Permissions Enabled',
        supported: DetectRTC.isWebsiteHasWebcamPermissions,
      },
      {
        position: '3',
        name: 'Microphone Permissions Enabled',
        supported: DetectRTC.isWebsiteHasMicrophonePermissions,
      },
      {
        position: '4',
        name: 'Web Sockets Support',
        supported: DetectRTC.isWebSocketsSupported,
      },
      {
        position: '5',
        name: 'Web Sockets Blocked',
        supported: DetectRTC.isWebSocketsBlocked,
      },
      {
        position: '6',
        name: 'Set Sink Id Support',
        supported: DetectRTC.isSetSinkIdSupported,
      },
      {
        position: '7',
        name: 'Apply Constraint Support',
        supported: DetectRTC.isApplyConstraintsSupported,
      },
      {
        position: '8',
        name: 'Audio Context Support',
        supported: DetectRTC.isAudioContextSupported,
      },
      {
        position: '9',
        name: 'Canvas Supports Stream Capturing',
        supported: DetectRTC.isCanvasSupportsStreamCapturing,
      },
      {
        position: '10',
        name: 'Create Media Stream Source Support',
        supported: DetectRTC.isCreateMediaStreamSourceSupported,
      },
      {
        position: '11',
        name: 'Get User Media Support',
        supported: DetectRTC.isGetUserMediaSupported,
      },
      {
        position: '12',
        name: 'Mobile Device',
        supported: DetectRTC.isMobileDevice,
      },
      {
        position: '13',
        name: 'Multi Monitor Screen Capturing Support',
        supported: DetectRTC.isMultiMonitorScreenCapturingSupported,
      },
      {
        position: '14',
        name: 'Multi Monitor Screen Capturing Support',
        supported: DetectRTC.isMultiMonitorScreenCapturingSupported,
      },
      {
        position: '15',
        name: 'Object RTC Support (Old Non Chromium Edge Versions implement this)',
        supported: DetectRTC.isORTCSupported,
      },
      {
        position: '16',
        name: 'Promises Support',
        supported: DetectRTC.isPromisesSupported,
      },
      {
        position: '17',
        name: 'RTP Sender Replace Tracks Support',
        supported: DetectRTC.isRTPSenderReplaceTracksSupported,
      },
      {
        position: '18',
        name: 'Remote Stream Processing Support',
        supported: DetectRTC.isRemoteStreamProcessingSupported,
      },
      {
        position: '19',
        name: 'Rtp Data Channels Support',
        supported: DetectRTC.isRtpDataChannelsSupported,
      },
      {
        position: '20',
        name: 'Screen Capturing Support',
        supported: DetectRTC.isScreenCapturingSupported,
      },
      {
        position: '21',
        name: 'Sctp Data Channels Support',
        supported: DetectRTC.isSctpDataChannelsSupported,
      },
      {
        position: '22',
        name: 'Video Supports Stream Capturing',
        supported: DetectRTC.isVideoSupportsStreamCapturing,
      }
    ]
    });
  }

  onCloseClick() {
    this.dialogRef.close();
  }

  // {
  //   position: '11',
  //   name: 'Desktop Capturing Support',
  //   supported: isDesktopCapturingSupported,
  // },
  transformer = (node: FileNode, level: number) => {
    return new FileFlatNode(!!node.children, node.filename, level, node.type);
  }

  private _getLevel = (node: FileFlatNode) => node.level;

  private _isExpandable = (node: FileFlatNode) => node.expandable;

  private _getChildren = (node: FileNode): Observable < FileNode[] > => of (node.children);

  hasChild = (_: number, _nodeData: FileFlatNode) => _nodeData.expandable;

}
