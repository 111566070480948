/// <reference path="../../../assets/liveswitch/fm.liveswitch.d.ts" />

import { Injectable } from "@angular/core";
import { LayoutManagerService } from "./layout-manager.service";


@Injectable({
    providedIn: 'root',
})
export class LocalMediaService {
    localCamera: fm.liveswitch.LocalMedia = null;
    screenShare: fm.liveswitch.LocalMedia = null;

    audioOnly: boolean;
    receiveOnly: boolean;
    simulcast = false;
    captureScreen: boolean;

    videoHeight = 320;
    videoWidth = 240;
    videoFps = 24;

    audioSourceInputs = [];
    videoSourceInputs = [];

    preferedCamera;
    preferedMicrophone;
    preferedMutedStatus = false;
    preferedCameraHiddenStatus = false;


    constructor(private layoutManagerService: LayoutManagerService) {}

    startLocalCamera(videoContainer: HTMLElement, audioOnly: boolean, receiveOnly: boolean, simulcast?: boolean): fm.liveswitch.Future<any> {
        const promise = new fm.liveswitch.Promise();
        try {
            this.checkIfLocalMediaAlreadyStarted(promise);
            this.audioOnly = audioOnly;
            this.receiveOnly = receiveOnly;
            this.layoutManagerService.initializeLayoutManager(videoContainer);

            if (!this.receiveOnly) {
                this.initializeLocalCameraObject();
                this.turnOnLocalCamera(promise);
            }
        } catch (exception) {
            promise.reject(new Error(exception));
        }

        return promise;
    }

    addExistingLocalCameraToContainer(videoContainer: HTMLElement) {
        const promise = new fm.liveswitch.Promise();
        try {
            this.layoutManagerService.initializeLayoutManager(videoContainer);

            if (!this.receiveOnly) {
                this.turnOnLocalCamera(promise);
            }
        } catch (exception) {
            promise.reject(new Error(exception));
        }

        return promise;
    }

    initializeLocalCameraObject() {
        const audio = true;

        if (!this.audioOnly) {
            const video = new fm.liveswitch.VideoConfig(this.videoWidth, this.videoHeight, this.videoFps);
            this.localCamera = new fm.liveswitch.LocalMedia(true, true, false);
            this.setSimulCastForLocalCamera();
        } else {
            this.localCamera = new fm.liveswitch.LocalMedia(audio, null, false);
        }
    }

    initializeScreenShareObject() {
        const audio = false;

        if (!this.audioOnly) {
            this.screenShare = new fm.liveswitch.LocalMedia(audio, true, true);
        } else {
            this.screenShare = new fm.liveswitch.LocalMedia(audio, null, true);
        }
    }

    turnOnLocalCamera(promise) {
        this.localCamera.start().then(
            () => {
                this.getAudioInputList();
                this.getCameraInputList();

                if (!this.audioOnly) {
                    const localView = this.localCamera.getView();
                    if (localView != null) {
                        localView.id = 'localView';
                        this.layoutManagerService.layoutManager.setLocalView(localView);
                    }
                }
                promise.resolve(null);
            },
            exception => {
                this.handleLocalCameraErrors(exception);
                promise.reject(null);
            },
        );
    }

    startScreenShare(promise) {
        this.screenShare.start().then(
            () => {
                const localView = this.screenShare.getView();
                localView.id = 'screenshare';
                this.layoutManagerService.layoutManager.addRemoteView('screenShare', localView);
                this.layoutManagerService.layoutManager.layout();
                promise.resolve(null);
            },
            exception => {
                this.handleLocalCameraErrors(exception);
                promise.reject(null);
            },
        );
    }

    async shareScreen() {
        const promise = new fm.liveswitch.Promise();
        try {
            await this.checkIfLocalScreenShareAlreadyStarted(promise);
            await this.initializeScreenShareObject();
            await this.startScreenShare(promise);
        } catch (exception) {
            promise.reject(new Error(exception));
        }

        return promise;
    }

    setSimulCastForLocalCamera() {
        if (this.simulcast) {
            const videoEncodings = [];
            videoEncodings.push(new fm.liveswitch.VideoEncodingConfig());
            videoEncodings.push(new fm.liveswitch.VideoEncodingConfig());
            videoEncodings.push(new fm.liveswitch.VideoEncodingConfig());

            videoEncodings[0].setBitrate(1024);
            videoEncodings[0].setFrameRate(30);

            videoEncodings[1].setBitrate(512);
            videoEncodings[1].setFrameRate(15);
            videoEncodings[1].setScale(0.5);

            videoEncodings[2].setBitrate(256);
            videoEncodings[2].setFrameRate(7.5);
            videoEncodings[2].setScale(0.25);

            this.localCamera.setVideoEncodings(videoEncodings);
        }
    }

    getAudioInputList() {
        this.localCamera.getAudioSourceInputs().then(
            inputs => {
                this.audioSourceInputs = inputs;
            },
            exception => {
                console.error(exception);
            },
        );
    }

    getCameraInputList() {
        this.localCamera.getVideoSourceInputs().then(
            inputs => {
                if (!this.audioOnly && !this.captureScreen) {
                    this.videoSourceInputs = inputs;
                }
            },
            exception => {
                console.error(exception);
            },
        );
    }

    setPlaceholderIfNoCamera() {}

    checkIfLocalMediaAlreadyStarted(promise: fm.liveswitch.Promise<any>) {
        if (this.localCamera !== null) {
            // ! Handle Error Here
            this.handleLocalCameraErrors(new Error('Local media has already been started.'));
            return promise.reject(new Error('Local media has already been started.'));
        }
    }

    checkIfLocalScreenShareAlreadyStarted(promise: fm.liveswitch.Promise<any>) {
        if (this.screenShare !== null) {
            // ! Handle Error Here
            this.handleLocalScreenShareErrors(new Error('Local media has already been started.'));
            return promise.reject(new Error('Screen Share has already been started.'));
        }
    }

    tearDownLocalCamera(destroy?: boolean): fm.liveswitch.Future<any> {
        const promise = new fm.liveswitch.Promise();
        try {
            if (this.localCamera === null) {
                promise.resolve(null);
                return promise;
            }

            this.localCamera.stop().then(
                () => {
                    // Tear down the layout manager.
                    const lm = this.layoutManagerService.layoutManager;
                    if (lm != null) {
                        lm.removeRemoteViews();
                        lm.unsetLocalView();
                        this.layoutManagerService.layoutManager = null;
                    }

                    // Tear down the local media.
                    if (this.localCamera !== null && destroy === true) {
                        this.localCamera.destroy();
                        this.localCamera = null;
                    }

                    promise.resolve(null);
                },
                ex => {
                    promise.reject(ex);
                },
            );
        } catch (ex) {
            promise.reject(ex);
        }
        return promise;
    }

    tearDownScreenShare() {
        const promise = new fm.liveswitch.Promise();
        try {
            if (this.screenShare === null) {
                promise.resolve(null);
                return promise;
            }

            this.screenShare.stop().then(
                () => {
                    // Tear down the layout manager.
                    const lm = this.layoutManagerService.layoutManager;
                    if (lm.getRemoteView('screenShare') !== undefined) {
                        lm.removeRemoteView('screenShare');
                        lm.layout();
                    }

                    // Tear down the local media.
                    if (this.screenShare != null) {
                        this.screenShare.destroy();
                        this.screenShare = null;
                    }

                    promise.resolve(null);
                },
                ex => {
                    promise.reject(ex);
                },
            );
        } catch (ex) {
            promise.reject(ex);
        }
        return promise;
    }

    handleLocalCameraErrors(error) {
        console.error(error);
    }

    handleLocalScreenShareErrors(error) {
        // this.eventsService.logErrorEvent(error, window.location.href);
        console.error(error);
    }
}
