/// <reference path="../../../assets/liveswitch/fm.liveswitch.d.ts" />

import { Injectable } from '@angular/core';
import { LocalMediaService } from './local-media.service';

@Injectable({
  providedIn: 'root'
})
export class LayoutManagerService {

    layoutManager: fm.liveswitch.DomLayoutManager;
    currentRemoteViews = [];

    constructor() { }

    initializeLayoutManager(videoContainer: HTMLElement) {
        this.layoutManager = new fm.liveswitch.DomLayoutManager(videoContainer);
    }

    destroyLayoutManager() {
        this.layoutManager = undefined;
    }

    setScreenShareLayout() {
    }

}
