/// <reference path="../../assets/liveswitch/fm.liveswitch.d.ts" />


import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Section } from '../interfaces/interfaces';
import * as DetectRTC from 'detectrtc';
import { LocalMediaService } from './services/local-media.service';
import { LayoutManagerService } from './services/layout-manager.service';
import { ActivatedRoute, Router } from '@angular/router';

export enum callType {
  Sfu = 1,
  Mcu = 2,
  Peer = 3
}

@Component({
  selector: 'app-test-screen',
  templateUrl: './test-screen.component.html',
  styleUrls: ['./test-screen.component.scss']
})
export class TestScreenComponent implements OnInit {

  @Output() logOutputs = new EventEmitter<Section>();

  token: string = '';
  userId = '2234334';
  client: fm.liveswitch.Client = new fm.liveswitch.Client(environment.telehealthGatewayUrl, environment.telehealthApplicationId, `${this.userId}`, `debug-page`, `${this.userId}`);


  localCamera: fm.liveswitch.LocalMedia = null;
  screenShare: fm.liveswitch.LocalMedia = null;

  audioOnly: boolean;
  receiveOnly: boolean;
  simulcast = false;
  captureScreen: boolean;

  videoHeight = 320;
  videoWidth = 240;
  videoFps = 24;

  audioSourceInputs = [];
  videoSourceInputs = [];

  preferedCamera;
  preferedMicrophone;
  preferedMutedStatus = false;
  preferedCameraHiddenStatus = false;

  callContainer: HTMLElement;

  currentSideNavComponent = null;

  currentAudioLevel = null;

  isLocalMicrophoneMuted = false;
  isLocalCameraMuted = false;

  screenShareUpstreamConnectionSFU: fm.liveswitch.SfuUpstreamConnection = null;
  screenShareUpstreamConnectionMCU: fm.liveswitch.McuConnection = null;

  localCameraInstance: fm.liveswitch.LocalMedia;

  audioInputList: fm.liveswitch.Future<fm.liveswitch.SourceInput[]>;
  videoInputList: fm.liveswitch.Future<fm.liveswitch.SourceInput[]>;


  remoteUserIds;

  hideNewMessageBadge = true;
  newMessageCount = 0;
  numMessageCount = 0;
  intervalSource: any;

  isRegistering = false;
  isRegistered = false;

  bytesRecieved = 0;
  bytesSent = 0;
  protocol;
  connectedPort;

  mcuConnection: fm.liveswitch.McuConnection;

  hasToken = false;


  constructor(
    private localMediaService: LocalMediaService,
    private layoutManager: LayoutManagerService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.initiateLogProvider();
    this.getTokenFromUrlParam();
  }

  getTokenFromUrlParam() {
    this.activatedRoute.queryParams.subscribe(params => {
      console.log(params);
      if (params.token) {
        this.hasToken = true;
        this.token = params.token;
      } else {
        this.hasToken = false;
      }
    })
  }

  setUpLayout() {

    if (this.token !== '') {
      this.createLog({ name: 'Check for Camera Microphone access.', log: 'Initializing Camera/Microphone', success: 3})

      this.callContainer = document.getElementById('call-container');


      this.localMediaService
        .startLocalCamera(this.callContainer, false, false, true)
        .then(() => this.onLocalMediaStartSuccess())
        .fail(exception => {
          console.error(exception);
        }).then(() => {
          this.getCurrentAudioLevels();
        });
    } else {
      this.createLog({ name: 'Application Error', log: 'No Token Provided. Please contact an Admin for token', success: 2});
    }


}

getCurrentAudioLevels() {
  this.localMediaService.localCamera.addOnAudioLevel(audioLevel => {
      this.currentAudioLevel = audioLevel * 1000;
  });
}

onLocalMediaStartSuccess() {
  this.createLog({ name: 'Check for Camera Microphone access.', log: 'Camera & Microphone Initialized Successfully', success: 1})
  this.localCameraInstance = this.localMediaService.localCamera;
  this.registerToLSGateway();
}

createMcuConnection(channel) {
  this.createLog({ name: 'Test MCU Connection', log: `Initializing MCU Connection to room ${channel.getId()}`, success: 3});
  var remoteMedia = new fm.liveswitch.RemoteMedia();
  var audioStream = new fm.liveswitch.AudioStream(this.localCameraInstance, remoteMedia);
  var videoStream = new fm.liveswitch.VideoStream(this.localCameraInstance, remoteMedia);
  var connection: fm.liveswitch.McuConnection = channel.createMcuConnection(audioStream, videoStream);
  this.mcuConnection = connection;
  this.layoutManager.layoutManager.addRemoteView(remoteMedia.getId(), remoteMedia.getView());
  connection.addOnStateChange(function(c) {
      if (c.getState() == fm.liveswitch.ConnectionState.Closing || c.getState() == fm.liveswitch.ConnectionState.Failing) {
        this.layoutManager.layoutManager.removeRemoteView(remoteMedia.getId());
      }
  });
  connection.setIceServers(environment.telehealthIceServers)
  connection.open().then((result) => {
      console.log("mixed connection established");
      this.createLog({ name: 'Test MCU Connection', log: `Connected to room ${channel.getId()}`, success: 1});

  }).fail(function(ex) {
      console.log("an error occurred");
      this.createLog({ name: 'Test MCU Connection', log: `MCU Connection failed ${ex.message}`, success: 3});

  });

  connection.setStatsInterval(1000);
  connection.addOnStats(() => {
    this.getConnectionStats(connection);
  });
}

closeConnection() {
  this.createLog({ name: 'Test MCU Teardown', log: `Attempting Tear Call Tear Down`, success: 3});



  this.localMediaService.tearDownLocalCamera(true).then(() => {
    this.localCameraInstance.stop().then(res => {
      console.log(res , 'cacac');
    }).fail(err => console.error(err));
  }).then(con => {
    this.mcuConnection.close().then(res => {
      console.log(res);
      this.client = new fm.liveswitch.Client(environment.telehealthGatewayUrl, environment.telehealthApplicationId, `${this.userId}`, `debug-page`, `${this.userId}`);
      this.mcuConnection = null;
      this.isRegistered = false;
    }).fail(err => console.error(err));
  });


  this.createLog({ name: 'Unregister from Telehealth Gateway', log: `Unregistering from the Telehealth Gateway`, success: 3});
}


getConnectionStats(connection: fm.liveswitch.McuConnection) {
    connection.getStats().then((stats: fm.liveswitch.ConnectionStats) => {
      const transport = stats.getStreams()[0].getTransport();

      if (transport) {


        let localCandidates = transport.getLocalCandidates();
        let remoteCandidates = transport.getRemoteCandidates();
        let activeCandidatePair = transport.getActiveCandidatePair();
        let activeLocalCandidateId = activeCandidatePair.getLocalCandidateId();
        let activeRemoteCandidateId = activeCandidatePair.getRemoteCandidateId();

        this.bytesRecieved = activeCandidatePair.getBytesReceived();
        this.bytesSent = activeCandidatePair.getBytesSent();
        console.log(remoteCandidates, localCandidates);
        for (var i = 0; i < localCandidates.length; i++) {
          var localCandidate = localCandidates[i];
          if (localCandidate.getId() == activeLocalCandidateId) {
              // this is the active local candidate
              // this.connectedPort = localCandidate[0].getPort();
              // check the protocol - UDP or TCP
              var localCandidateProtocol = localCandidate.getProtocol();
              console.log(activeLocalCandidateId)
              switch (localCandidateProtocol) {
                case 1:
                    this.protocol = 'UDP';
                  break;
                  case 2:
                    this.protocol = 'TCP';
                  break;
                  case 3:
                    this.protocol = 'TLS';
                  break;
                  case 4:
                    this.protocol = 'Unknown';
                  break;

                default:
                  break;
              }

              if (localCandidate.getIsRelayed()) {
                  // check the relay server IP
                  var relayServerIPAddress = localCandidate.getIPAddress();
              }
          }
      }
      for (var i = 0; i < remoteCandidates.length; i++) {
          var remoteCandidate = remoteCandidates[i];
          if (remoteCandidate.getId() == activeRemoteCandidateId) {
              // this is the active remote candidate
              if (remoteCandidate.getIsRelayed()) {
                  // check the relay server IP
                  var relayServerIPAddress = remoteCandidate.getIPAddress();
                  console.log(relayServerIPAddress);
                  console.log(remoteCandidate.getTypeString());
                  console.log(remoteCandidate.getPort());
              }
          }
      }

      }
    });
}

  initiateLogProvider() {
    fm.liveswitch.Log.registerProvider(new fm.liveswitch.ConsoleLogProvider(fm.liveswitch.LogLevel.Debug));
  }

  registerToLSGateway() {
    this.isRegistering = true;
    this.createLog({name: 'Connection with the Telehealth Gateway', log: `Attempting Connection to Telehealth Gateway`, success: 3});
    this.addClientStateChanges(this.client);

    this.client.register(this.token)
    .then((channels) => {
      console.log(277, channels);
      this.isRegistering = false;
      this.createLog({name: 'Connection with the Telehealth Gateway', log: `Gateway Application found:  ${this.client.getApplicationId()}`, success: 4});
      this.createMcuConnection(channels[0]);
    })
    .fail(ex => {
      this.createLog({name: 'Connection with the Telehealth Gateway', log: `Failure: ${ex.message}`, success: 2});
    });

  }


  addClientStateChanges(client: fm.liveswitch.Client) {
    client.addOnStateChange(clientState => {
        if (clientState.getState() === fm.liveswitch.ClientState.Registering) {
            fm.liveswitch.Log.info('client is registering');
            this.createLog({name: 'Connection with the Telehealth Gateway', log: `Client Machine attempting registration with the gateway`, success: 4});
        } else if (clientState.getState() === fm.liveswitch.ClientState.Registered) {
            fm.liveswitch.Log.info('client is registered');
            this.isRegistered = true;
            this.createLog({name: 'Connection with the Telehealth Gateway', log: `Client Machine registered with the gateway`, success: 1});
        } else if (clientState.getState() === fm.liveswitch.ClientState.Unregistering) {
            fm.liveswitch.Log.info('client is unregistering');
            this.createLog({name: 'Connection with the Telehealth Gateway', log: `Client Machine unregistering with the gateway`, success: 4});
        } else if (clientState.getState() === fm.liveswitch.ClientState.Unregistered) {
            // ? Client has failed for some reason:
            fm.liveswitch.Log.info('client is unregistered');
            this.isRegistered = false;
            // this.createLog({name: 'Connection with the Telehealth Gateway', log: `Client Machine failed to register with the gateway`, success: 4});
        }
    });
}


  checkTURNServer(turnConfig, timeout = 5000) {
      return new Promise(async (resolve, reject) => {
          const pc: any = new RTCPeerConnection({iceServers: [turnConfig]});
          let promiseResolved = false;
          // Stop waiting after X milliseconds and display the result
          setTimeout(() => {
              if(promiseResolved)
                  return;
              promiseResolved = true;
              resolve(false);
          }, timeout);
          // Create a bogus data channel
          pc.createDataChannel('');
          // Listen for candidates
          pc.onicecandidate = (ice: any) => {
              if(promiseResolved || ice === null || ice.candidate === null)
                  return;
              if(ice.candidate.type === 'relay') {
                  promiseResolved = true;
                  resolve({ success: true,
                            candidtateType: ice.candidate.type,
                            connectionProtocol: ice.candidate.protocol,
                            connectedIp: ice.candidate.relatedAddress,
                            connectedPort: ice.candidate.relatedPort,
                  });
              }
          };
          // Create offer and set local description
          const offer = await pc.createOffer();
          await pc.setLocalDescription(offer);
      });
  }

  runStunTest() {
    this.createLog({name: 'Test Turn Server Connectivity', log: 'Attempting connection to turn server', success: 3});
    this.checkTURNServer({
      urls: ['turn:' + '34.243.102.156' + ':3478', 'turns:' + '34.243.102.156' + ':5349'],
      username: "user",
      credential: "root",
      credentialType: 'password'
  }).then(
      (active: any) => {

        if (active.connectedPort !== undefined) {
          this.createLog({name: 'Turn Server Candidate Details', log: `Connected over ${active.connectionProtocol} \n`, success: 4});
          this.createLog({name: 'Turn Server Candidate Details', log: `Connected on port ${active.connectedPort} \n`, success: 4});
          this.createLog({name: 'Turn Server Candidate Details', log: `Connected on ip ${active.connectedIp} \n`, success: 4});
          this.createLog({name: 'Test Turn Server Connectivity', log: `Connection to turn server was a success.`, success: 1});
        } else {
          this.createLog({name: 'Test Turn Server Connectivity', log: 'Connection to turn server failed. Please try again ' + active, success: 2})
        }



      }
  ).catch(
      e => this.createLog({name: 'Test Turn Server Connectivity', log: 'Connection to turn server failed. Please try again', success: 2})
  );
  }

  createLog(log: Section) {
    this.logOutputs.emit(log);
  }

}
