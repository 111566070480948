import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule, MatIconModule, MatButtonModule, MatListModule, MatDialogModule, MatTableModule, MatTreeModule, MatProgressBarModule } from '@angular/material';
import { AlertConfig, AlertModule } from 'ngx-bootstrap';
import { TestLogsComponent } from './test-logs/test-logs.component';
import { TelehealthTesterComponent } from './telehealth-tester/telehealth-tester.component';
import { TestScreenComponent } from './test-screen/test-screen.component';
import { DetectRtcModalComponent } from './detect-rtc-modal/detect-rtc-modal.component';

@NgModule({
  declarations: [
    AppComponent,
      TestLogsComponent,
      TelehealthTesterComponent,
      TestScreenComponent,
      DetectRtcModalComponent
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatTableModule,
    MatTreeModule,
    MatProgressBarModule,
    MatListModule,
    AlertModule.forRoot()
  ],
  providers: [AlertConfig],
  bootstrap: [AppComponent],
  entryComponents: [DetectRtcModalComponent]
})
export class AppModule { }
