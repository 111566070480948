import { Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DetectRtcModalComponent } from './detect-rtc-modal/detect-rtc-modal.component';

@Component({
  selector: 'sal-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'telehealth-connectivity-test-page';

  constructor(public dialog: MatDialog) {

  }

  openBrowserTestDialog() {
    const dialogRef = this.dialog.open(DetectRtcModalComponent, {
      role: 'dialog',
      ariaLabel: 'Modal Dialog to determine browser support for RTC Features',
      minWidth: '90vw',
      maxHeight: '90vh' //you can adjust the value as per your view

    });
  }
}
