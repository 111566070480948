
import { Component, OnInit } from '@angular/core';
import { Section } from '../interfaces/interfaces';

@Component({
  selector: 'app-telehealth-tester',
  templateUrl: './telehealth-tester.component.html',
  styleUrls: ['./telehealth-tester.component.scss']
})
export class TelehealthTesterComponent implements OnInit {

  telehealthLogs: Section[] = [];

  constructor() { }

  ngOnInit() {
  }

  handleLogOutput(output: Section) {
    this.telehealthLogs.push(output);
  }

}
