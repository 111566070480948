import { Component, Input, OnInit } from '@angular/core';
import { Section } from '../interfaces/interfaces';

@Component({
  selector: 'app-test-logs',
  templateUrl: './test-logs.component.html',
  styleUrls: ['./test-logs.component.scss']
})
export class TestLogsComponent implements OnInit {

  @Input() logs: Section[] = [];

  constructor() { }

  ngOnInit() {
  }

}
